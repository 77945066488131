import axios from "@axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchServices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getServices", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchService(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getOneService/${id}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addService(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addService", productData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateService(ctx, queryParams) {
      const id = queryParams.get("id");
      queryParams.delete("id");
      return new Promise((resolve, reject) => {
        axios
          .put(`/updateService/${id}`, queryParams, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
              idOffice: queryParams.get("idOffice") || undefined,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateServiceStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const status = { status: queryParams.status };
        axios
          .put(`/updateServiceStatus/${queryParams.id}`, { params: status })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteService(ctx, queryParams) {
      let cloverId = 0;
      let detProduct = 0;

      /// Esto es cuando viene de una oficina si no es 0 Por es borrado masivo
      if (queryParams.data.officeService.length > 0) {
        cloverId = queryParams.data.officeService[0].cloverId;
        detProduct = queryParams.data.officeService[0].id;
      }

      const data = {
        idRol: queryParams.data.idRol,
        idOffice: queryParams.data.idOffice,
        cloverId,
        detProduct,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteService/${queryParams.data.id}`, data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchHelpers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelpes", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchServiceCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getCategoryService", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchCategoriesProductos(ctx, queryParams) {
      console.log("Que traifo aqui", queryParams);
      return new Promise((resolve, reject) => {
        axios
          .get("/getCategories", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addServiceCategory(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/createServiceCategory", productData)
          .then((response) => resolve(response))
          .catch((error) => reject(error.response.data.msg));
      });
    },
    deleteServiceCategory(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteServiceCategory/${productData.id}`, productData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
